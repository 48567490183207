html,
body {
  font-family: "VT323", monospace;
  margin: 0;
  overflow: hidden;
  background-color: black;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
button {
  font-family: "VT323", monospace;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1;
  border-radius: 0;
  /* transition: color 0.25s linear 0s;
  transition: background-color 0.25s linear 0s; */
}
button:focus,
button:active {
  border-radius: 0;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  margin-bottom: 2rem;
  padding: 0;
}
#ui {
  position: absolute;
  font-size: 1.5rem;

  /* border: 1px solid red; */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* padding: 2rem; */
  top: 2rem;
  left: 2rem;
}
.ui-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}
.text {
  z-index: 1;
}
.generate {
  background: none;
  font-size: 2rem;
  color: #fff;
  border: 1px solid #fff;
}
.generate:hover {
  color: rgb(247, 206, 233);
}
#keep {
  background: none;
  font-size: 1.5rem;
  color: #fff;
  border: 1px solid #fff;
  z-index: 1;
  position: relative;
}
#keep:hover {
  background-color: white;
  color: black;
}
.keep-wrapper {
  position: absolute;
  bottom: 2rem;
}
#question,
#audio {
  background: none;
  font-size: 2rem;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;
  right: 2rem;
  top: 2rem;
}
#question:hover {
  color: #3c61f0;
}
#social {
  background: none;
  font-size: 2rem;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  padding: 1rem;
  text-decoration: none;
}
#social:hover {
  color: #d6ad3d;
}
#clear {
  background: none;
  font-size: 2rem;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;
  right: 2rem;
  bottom: 50%;
  padding: 1rem 0.4rem 1.2rem 0.4rem;
  text-decoration: none;
}
#clear:hover {
  color: #34eb64;
}
#audio {
  right: 6rem;
  opacity: 1;
  color: yellow;
}
#audio:hover {
  color: yellow;
}
#audio.muted {
  border: 1px solid white;
  color: white;
}
#close {
  background: none;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  color: white;
  background-color: black;
  border: 1px solid white;
  width: 12rem;
  display: none;
}
#close:hover {
  background-color: red;
  color: black;
  border-color: black;
}
#controls {
  background: none;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  color: white;
  background-color: black;
  border: 1px solid white;
  width: 12rem;
}
#controls:hover {
  background-color: blue;
  color: black;
  border-color: black;
}
.buttons {
  display: flex;
}
.buttons button:first-child {
  margin-right: 1rem;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
#about {
  padding: 5rem;
  opacity: 0.95;
  top: 50%;
  transform: translateY(-50%);
  height: 75%;
  width: 70%;
  background: black;
  font-size: 2rem;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
h3 {
  margin: 0 0 2rem 0;
}
#description {
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow-y: scroll;
  margin-bottom: 2rem;
  padding-right: 1rem;
  flex-grow: 1;
}
#description::-webkit-scrollbar,
#howto::-webkit-scrollbar {
  width: 1rem;
}

#description::-webkit-scrollbar-thumb,
#howto::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

#howto {
  display: none;
  flex-direction: column;
  overflow-y: scroll;
  height: 80%;
  flex-grow: 1;
  margin-bottom: 2rem;
  padding-right: 1rem;
}
#about p {
  margin-top: 0;
}

#chatroom-container {
  position: absolute;
  display: flex;
  bottom: 2rem;
  left: 2rem;
  border: 1px solid #fff;
  z-index: 2;
}

#chatroom {
  position: relative;
}

#chatroom::after {
  position: absolute;
  z-index: -1;
  display: block;
  top: 0;
  left: 0;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  background-color: black;
  content: "";
}

#name-display-row {
  border: 1px solid white;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}
#name-display {
  padding: 0 0.5rem;
}
#logout {
  background-color: transparent;
  border: none;
  color: white;
  padding: 0.5rem 0.65rem;
}

#name-input,
#message-input {
  background-color: transparent;
  border: 1px solid #fff;
  color: white;
  margin: 0.5rem;
  flex-grow: 1;
}

#messages {
  height: 12rem;
  overflow-y: scroll;
  color: yellow;
  padding: 0 0.5rem;
  width: 15rem;
  margin: 0.2rem 0;
}
#messages::-webkit-scrollbar {
  width: 1em;
}
#messages::-webkit-scrollbar-track {
  background-color: transparent;
}
#messages::-webkit-scrollbar-thumb {
  border: 1px solid white;
}

#messages li {
  margin-bottom: 0.25rem;
}
#message-form {
  display: none;
}
#name-form {
  display: flex;
}
#message-form,
#name-form {
  font-size: 1rem;
  border: 1px solid white;
  justify-content: space-between;
  align-items: center;
}
#message-form button,
#name-form button {
  background-color: transparent;
  color: white;
  padding: 0.2rem;
  margin-right: 0.5rem;
}
#users-online {
  position: absolute;
  color: white;
  top: -1.5rem;
  color: white;
}

.slide {
  transition: top 1s, bottom 1s, left 1s, right 1s;
}

#ui.slide-left,
#chatroom-container.slide-left {
  left: -20rem;
}
#question.slide-right,
#audio.slide-right,
#social.slide-right {
  right: -20rem;
}

@media screen and (max-width: 767px) {
  h3 {
    font-size: 1.7rem;
  }
  #about {
    padding: 1.5rem;
    font-size: 1.5rem;
    height: 85%;
    width: 80%;
  }
  #description {
    padding-right: 0.5rem;
  }
  #about li {
    margin-bottom: 1rem;
  }
  #about ul {
    margin-top: 0rem;
  }
  #question {
    top: 4rem;
    right: 0.5rem;
  }
  #audio {
    top: 0.5rem;
    right: 0.5rem;
  }
  #ui {
    top: 0.5rem;
    left: 0.5rem;
  }
  #social {
    bottom: 0.5rem;
    right: 0.5rem;
  }
  #chatroom-container {
    left: 0.5rem;
    bottom: 0.5rem;
  }
  #messages {
    width: 9rem;
  }
  #name-input,
  #message-input {
    width: 5.5rem;
  }
  #clear {
    right: 0.5rem;
    bottom: 50%;
    transform: translateY(50%);
  }
}
